import React, { useEffect, useContext, Suspense } from 'react'
import { Route, BrowserRouter as Router, withRouter, Switch } from 'react-router-dom'
import PrivateRoute from './privateRoute'
import AuthContext from '../context/auth/authContext'
import CommonContext from '../context/common/commonContext'
import Header from '../components/organisms/Header'
import Footer from '../components/organisms/Footer'
import Accounts from '../views/Admin/Accounts'

const Login = React.lazy(() => import('../views/Login'))
const ForgotPassword = React.lazy(() => import('../views/ForgotPassword'))
const ResetPassword = React.lazy(() => import('../views/ResetPassword'))
const OnBoarding = React.lazy(() => import('../views/OnBoarding'))
const RegisterSuccess = React.lazy(() => import('../views/OnBoarding/success'))
const PaymentOptions = React.lazy(() => import('../views/PaymentOptions'))
const Payments = React.lazy(() => import('../views/Dashboard/Payments'))
const Transfers = React.lazy(() => import('../views/Dashboard/Transfers'))
const ViewPayment = React.lazy(() => import('../views/Dashboard/Payments/viewPayment'))
const ViewTransfer = React.lazy(() => import('../views/Dashboard/Transfers/viewTransfer'))
const ViewCustomer = React.lazy(() => import('../views/Dashboard/ViewCustomer'))
const PaymentDetails = React.lazy(() => import('../views/Dashboard/Payments/PaymentDetails'))
const CustomerDetails = React.lazy(() => import('../views/Dashboard/CustomerDetails'))
const Home = React.lazy(() => import('../views/Home'))
const Dashboard = React.lazy(() => import('../views/Dashboard'))
const Customers = React.lazy(() => import('../views/Dashboard/Customers'))
const Balance = React.lazy(() => import('../views/Dashboard/Balance'))
const Payouts = React.lazy(() => import('../views/Dashboard/Payments/Payouts'))
const PayoutDetails = React.lazy(() => import('../views/Dashboard/Payments/PayoutDetails'))
const Disputes = React.lazy(() => import('../views/Dashboard/Payments/Disputes'))
const DisputeDetails = React.lazy(() => import('../views/Dashboard/Payments/DisputeDetails'))
// const AllTransactions = React.lazy(() => import('../views/Dashboard/Payments/AllTransactions'))
const Profile = React.lazy(() => import('../views/Profile'))
const Onboard = React.lazy(() => import('../views/StaticPages/Onboard'))
const Presentation = React.lazy(() => import('../views/Presentation'))
const Integrationdoc = React.lazy(() => import('../views/StaticPages/Integration'))
const DeclineCodes = React.lazy(() => import('../views/StaticPages/DeclineCodes'))
const Privacy = React.lazy(() => import('../views/StaticPages/Privacy'))
const Terms = React.lazy(() => import('../views/StaticPages/Terms'))
const SplitPayment = React.lazy(() => import('../views/SplitPayment'))
const Vendors = React.lazy(() => import('../views/Dashboard/Vendors'))
const Invoice = React.lazy(() => import('../views/CreateTransaction/Invoice'))
const Invoices = React.lazy(() => import('../views/Dashboard/Invoices'))
const InvoiceDetails = React.lazy(() => import('../views/Dashboard/InvoiceDetails'))
const Subscriptions = React.lazy(() => import('../views/Dashboard/Subscriptions'))
const Settings = React.lazy(() => import('../views/Dashboard/Settings'))
const Report = React.lazy(() => import('../views/Report'))
const Quickbooks = React.lazy(() => import('../views/Quickbooks'))
const EmployeeDashboard = React.lazy(() => import('../views/EmployeeDashboard'))

const ConnectedAccounts = React.lazy(() => import('../views/ConnectedAccounts'))
const SellerDashboard = React.lazy(() => import('../views/StaticPages/SellerDashboard'))
const AdminLogin = React.lazy(() => import('../views/Admin/Login'))
const AdminCustomers = React.lazy(() => import('../views/Admin/Dashboard/Customers'))
const AdminRevenue = React.lazy(() => import('../views/Admin/Dashboard/Revenue'))
const AdminViewCharge = React.lazy(() => import('../views/Admin/Dashboard/Revenue/ViewCharge'))
const TwoFactor = React.lazy(() => import('../views/Admin/Settings/TwoFactorAuth'))
const AdminViewCustomers = React.lazy(() =>
    import('../views/Admin/Dashboard/Customers/ViewCustomer'),
)
const AdminPayments = React.lazy(() => import('../views/Admin/Dashboard/Payments'))
const AdminViewPayments = React.lazy(() => import('../views/Admin/Dashboard/Payments/ViewPayment'))
// const AdminConnectedAccounts = React.lazy(() =>
//     import('../views/Admin/Dashboard/ConnectedAccounts'),
// )
// const AdminSellerAccounts = React.lazy(() => import('../views/Admin/Dashboard/SellerAccounts'))

const AdminTemplates = React.lazy(() => import('../views/Admin/Dashboard/Templates'))

// const AuctionIO = React.lazy(() => import('../views/Admin/Dashboard/AuctionIO'))
// const AuctionIOSettings = React.lazy(() => import('../views/Admin/Dashboard/AuctionIO/Settings'))

const SwitchUser = React.lazy(() => import('../views/SwitchUser'))

const Routes = (props) => {
    const authContext = useContext(AuthContext)
    const commonContext = useContext(CommonContext)

    const { loadUser } = authContext

    const { getGlobalVariable } = commonContext

    useEffect(() => {
        if (localStorage.token) {
            loadUser()
        }
        getGlobalVariable()
    }, [])

    return (
        <>
            {props.location.pathname === '/' ||
            props.location.pathname === '/onboarding' ||
            props.location.pathname === '/integrationdocument' ||
            props.location.pathname === '/declinecodes' ||
            props.location.pathname === '/presentation' ||
            props.location.pathname === '/privacy' ||
            props.location.pathname === '/terms' ? (
                <Header />
            ) : null}
            <Suspense
                fallback={
                    <div className="fallbackLoader">
                        <img src="./assets/images/loader-light.gif" alt="loader" />
                    </div>
                }
            >
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/onboarding" component={Onboard} />
                    <Route exact path="/integrationdocument" component={Integrationdoc} />
                    <Route exact path="/declinecodes" component={DeclineCodes} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/forgot_password" component={ForgotPassword} />
                    <Route exact path="/reset_password/:token" component={ResetPassword} />
                    <Route exact path="/register" component={OnBoarding} />
                    <Route exact path="/presentation" component={Presentation} />
                    <Route exact path="/activation/success" component={RegisterSuccess} />
                    <Route exact path="/paymentOptions" component={PaymentOptions} />
                    <PrivateRoute exact path="/dashboard" component={Dashboard} />
                    <PrivateRoute exact path="/payments/allTransactions" component={Transfers} />
                    <PrivateRoute exact path="/payment/:id" component={ViewPayment} />
                    <PrivateRoute exact path="/customer/:id" component={ViewCustomer} />
                    <PrivateRoute exact path="/payments" component={Payments} />
                    <PrivateRoute exact path="/transfers" component={Transfers} />
                    <PrivateRoute exact path="/transfer/:id" component={ViewTransfer} />
                    <PrivateRoute exact path="/payouts" component={Payouts} />
                    <PrivateRoute exact path="/payments/:action" component={Payments} />
                    <PrivateRoute exact path="/balance" component={Balance} />
                    <PrivateRoute exact path="/disputes" component={Disputes} />
                    <PrivateRoute exact path="/disputes/:id" component={DisputeDetails} />
                    <PrivateRoute exact path="/customers" component={Customers} />
                    <PrivateRoute exact path="/profile" component={Profile} />
                    <PrivateRoute exact path="/paymentDetails" component={PaymentDetails} />
                    <PrivateRoute exact path="/customerDetails" component={CustomerDetails} />
                    <PrivateRoute exact path="/payoutDetails/:payoutId" component={PayoutDetails} />
                    <Route exact path="/privacy" component={Privacy} />
                    <Route exact path="/terms" component={Terms} />
                    <PrivateRoute exact path="/splitPayment" component={SplitPayment} />
                    <PrivateRoute exact path="/invoice" component={Invoices} />
                    <PrivateRoute exact path="/invoice/:id" component={InvoiceDetails} />
                    <PrivateRoute exact path="/subscription" component={Subscriptions} />
                    <PrivateRoute exact path="/settings" component={Settings} />
                    <PrivateRoute exact path="/report" component={Report} />
                    <PrivateRoute exact path="/quickbooks" component={Quickbooks} />
                    <PrivateRoute exact path="/connectedAccounts" component={ConnectedAccounts} />
                    <Route exact path="/sellerDashboard/:token" component={SellerDashboard} />
                    <PrivateRoute exact path="/dashboard" component={Balance} />
                    <PrivateRoute exact path="/vendors" component={Vendors} />
                    <PrivateRoute exact path="/employeeDashboard" component={EmployeeDashboard} />
                    <Route exact path="/admin/login" component={AdminLogin} />
                    <Route exact path="/admin/accounts" component={Accounts} />
                    <Route exact path="/admin/accounts/:action" component={Accounts} />
                    <Route exact path="/admin/customers" component={AdminCustomers} />
                    <Route exact path="/admin/customer/:id" component={AdminViewCustomers} />
                    <Route exact path="/admin/disputes/:id" component={DisputeDetails} />
                    <Route
                        exact
                        path="/admin/disputes"
                        render={() => <Disputes userType="admin" />}
                    />

                    <Route exact path="/admin/revenue" component={AdminRevenue} />
                    <Route exact path="/admin/revenue/:id" component={AdminViewCharge} />
                    <Route exact path="/admin/payments" component={AdminPayments} />
                    <Route exact path="/admin/payments/:action" component={AdminPayments} />
                    <Route exact path="/admin/payment/:id" component={AdminViewPayments} />
                    <Route exact path="/admin/settings/twofactorauth" component={TwoFactor} />
                    {/* <Route
                        exact
                        path="/admin/connectedAccounts"
                        component={AdminConnectedAccounts}
                    />
                    <Route
                        exact
                        path="/admin/connectedAccount/:id"
                        component={AdminSellerAccounts}
                    /> */}
                    <Route exact path="/admin/templates" component={AdminTemplates} />
                    {/* <Route exact path="/admin/auctionIO" component={AuctionIO} />
                    <Route exact path="/admin/auctionIO/settings" component={AuctionIOSettings} /> */}
                    <Route exact path="/switch" component={SwitchUser} />
                </Switch>
            </Suspense>
            {props.location.pathname === '/' ||
            props.location.pathname === '/onboarding' ||
            props.location.pathname === '/integrationdocument' ||
            props.location.pathname === '/declinecodes' ||
            props.location.pathname === '/presentation' ||
            props.location.pathname === '/privacy' ||
            props.location.pathname === '/terms' ? (
                <Footer />
            ) : null}
        </>
    )
}
export default withRouter(Routes)
